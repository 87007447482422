@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilites {
  .poster-gradient {
    background: linear-gradient(
      0deg,
      hsla(0, 0%, 0%, 0.8),
      hsla(0, 0%, 25%, 0.2) 23%,
      hsla(0, 0%, 100%, 0) 35%,
      transparent
    );
  }

  .text-shadow-sm {
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}
